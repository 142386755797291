<template>
  <div>
    <title>SIM INVENTORY ~ ADD PROCESS PRODUCT DATA</title>
    <section class="content-header">
      <h1>
        Add Process Product Data
        <br />
        <h4>Please enter the product transaction data process</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Process Product Transaction</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Process Product Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Produk Number</label>
                    <input
                      type="text"
                      v-model="isidata.produknop"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Nomor Produk"
                    />
                    <input
                      type="hidden"
                      v-model="isidata.idxproduk"
                      autocomplete="off"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getproduk()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Part Number </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.partnop"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Process Code</label>
                    <input
                      type="text"
                      v-model="isidata.kodeproses"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Kode Proses"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getproses()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Process Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.prosesnm"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatanp"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/produk">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprod",
  data() {
    return {
      loading: false,
      isidata: {
        produknop: "",
        partnop: "",
        idxproduk: "",
        kodeproses: "",
        prosesnm: "",
        catatanp: ""
      }
    };
  },
  methods: {
    async getproduk() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_d/getproduk_dbynomor_produk?nomor_produk=" + this.isidata.partnod;
      const urlAPIget =
        this.$apiurl +
        "produk_d/getproduk_dbynomor_produk?nomor_produk=" +
        this.isidata.produknop;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Product Number not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.partnop = resp.data.data.part_no;
            this.isidata.idxproduk = resp.data.data.id;
            this.isidata.produknop = resp.data.data.nomor_produk;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getproses() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/daftar_proses/getdaftar_prosesbykode?kode_proses=" + this.isidata.partnod;
      const urlAPIget =
        this.$apiurl +
        "daftar_proses/getdaftar_prosesbykode?kode_proses=" +
        this.isidata.kodeproses;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, process not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.prosesnm = resp.data.data.nama_proses;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveData() {
      this.loading = true;
      var nop = this.isidata.produknop;
      var idxpp = this.isidata.idxproduk;
      var partp = this.isidata.partnop;
      var kodepro = this.isidata.kodeproses;
      var namapros = this.isidata.prosesnm;
      var notesp = this.isidata.catatanp;

      if (nop == "" || idxpp == "" || partp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodepro == "" || namapros == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Process Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (notesp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          id_produk_d: idxpp,
          kode_proses: kodepro,
          notes: notesp,
          kode_user: kodeuser
        };

        var tokenlogin = sessionStorage.getItem("token");
        // var urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/produk_d_proses/saveproduk_d_proses";
        var urlAPIsavedata =
          this.$apiurl + "produk_d_proses/saveproduk_d_proses";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "produkheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "produkheader" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>

